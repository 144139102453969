
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
.Section {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 60vh;
  display: flex;
  /*height: auto;*/
  align-items: stretch;
  font-family: 'Jost', sans-serif;
}

.Section-mobile {
  text-align: center;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 20px;
  margin-bottom: 5px;
}

.title {
  /*background-color: #FF0000;*/
  display: inline-flex;
  flex-direction: row;
  width: 28%;
  /*min-height: inherit;*/
  margin-right: 1%;
  margin-left:1%;
  vertical-align: middle;
  align-items: center;

}

.title-content {
  user-select: none;
  font-size: 30px;
  margin: auto;
}

.content-paper {
  display: inline-flex;
  width:67%;
  min-height: inherit;
  margin-left: 1%;
  margin-right: 1%;
  vertical-align: top;
  text-align: left;
}

.content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 80%;
}
.content-mobile {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 0vh;
  padding-bottom: 5vh;
  width: 80%;
  font-size: calc(2px + 2vmin);
  text-align: left;
}

h2 {
  margin-top: none;
  margin: 0px 0px 0px 0px;
}
