.nav-list {
  width: 33%;
  display: inline-block;

}

.description {
  width: 66%;
  margin-left: 2%;
  display: inline-block;
}

.projects {
  display: flex;
  width: 100%;
}
