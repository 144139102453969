@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-color: #d5d9d3;/*#BBBFB7;*/
  min-height:100vh;
  padding-bottom: 10vh;
}
.App-header {
  background-color: #292E3D;
  min-height: 8vh;
  display: flex;
  padding: 0px;
  padding-left: 4vw;
  padding-right: 4vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
  font-family: 'Roboto Slab', serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.AppSection {
  background-color: black;
}

.icon {
  border-radius: 50%;
  width: 8vw;
  height: 8vw;
}

img {
  margin:auto;
  align-self: center;
}


.Section {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 60vh;
  display: flex;
  /*height: auto;*/
  align-items: stretch;
  font-family: 'Jost', sans-serif;
}

.Section-mobile {
  text-align: center;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 20px;
  margin-bottom: 5px;
}

.title {
  /*background-color: #FF0000;*/
  display: inline-flex;
  flex-direction: row;
  width: 28%;
  /*min-height: inherit;*/
  margin-right: 1%;
  margin-left:1%;
  vertical-align: middle;
  align-items: center;

}

.title-content {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 30px;
  margin: auto;
}

.content-paper {
  display: inline-flex;
  width:67%;
  min-height: inherit;
  margin-left: 1%;
  margin-right: 1%;
  vertical-align: top;
  text-align: left;
}

.content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 80%;
}
.content-mobile {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 0vh;
  padding-bottom: 5vh;
  width: 80%;
  font-size: calc(2px + 2vmin);
  text-align: left;
}

h2 {
  margin-top: none;
  margin: 0px 0px 0px 0px;
}

.section {
  opacity: 0;
  transform: translateY(15vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.section.visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.nav-list {
  width: 33%;
  display: inline-block;

}

.description {
  width: 66%;
  margin-left: 2%;
  display: inline-block;
}

.projects {
  display: flex;
  width: 100%;
}

